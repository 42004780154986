import { ILeaveRequestCreatedEvent } from '@vacationtracker/shared/types/leave-request'
import { Auth } from 'aws-amplify'
import axios from 'axios'

interface IFeatureFlagsResponse {
  enabledFeatures: string[]
}

export async function getFeatureFlags(role, microsoftTab): Promise<IFeatureFlagsResponse> {
  const session = await Auth.currentSession()
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/core/status?role=${role.toLowerCase()}&eventType=MS_TAB_${microsoftTab}_OPENED`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
    },
  })
  return response.data
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function sendCoreEvent<T>(event: T): Promise<any> {
  const session = await Auth.currentSession()
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/core/event`, event, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
    },
  })
  return response.data
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function validateLeaveRequest(event: Partial<ILeaveRequestCreatedEvent>): Promise<any> {
  const session = await Auth.currentSession()
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/core/leave-request-validate`, event, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
    },
  })
  return response.data
}