import React, { useEffect, useState } from 'react'
import { Typography, notification, Card, Alert } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import * as Sentry from '@sentry/react'
import { useManualQuery } from 'graphql-hooks'
import { useIntl } from 'react-intl'
import { addYears, isWithinInterval, format, areIntervalsOverlapping, set } from 'date-fns'
import dayjs from 'dayjs' 

import { MicrosoftTeams } from '../../services/auth/microsoft/microsoft'
import {
  getLeaveTypesForUser,
  getOverlappingUsersInfo,
  getConnectedCalendar,
  getTeamsForApprover,
  getSuggestedSubstituteApprovers,
  getCompanyPlan,
  getAutomations,
  getLeaveRequestData
} from '../../graphql/custom-queries'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setLocale } from '../../store/LocaleSlice'
import { selectUserIdSlice } from '../../store/UserIdSlice'
import { getPrefferedLanguage } from '../../util/get-preffered-language'
import { addCorrelationId } from '../../store/NotificationsSlice'
import { validateLeaveRequest, sendCoreEvent } from '../../services/api/vacationtracker'
import { track } from '../../services/analytics/analytics'
import { getLeavePeriod } from '@vacationtracker/shared/functions/get-leave-period'
import { getFeatureFlags } from '../../services/api/vacationtracker'
import { selectFeatureFlagsSlice, setFeatureFlags } from '../../store/FeatureFlagsSlice'
import { displayLeaveInDaysAndHours } from '@vacationtracker/shared/components/display-leave-in-days-and-hours'
import { formatDateToLocale } from '@vacationtracker/shared/components/formatted-date'
import { shouldShowFutureAccruedDaysInfo } from '@vacationtracker/shared/functions/should-show-future-accrued-days-info'

import CircularProgress from '../../components/circular-progress'
import IntlMessages from '../../util/IntlMessages'
import {
  LeaveRequestSummary,
  LeaveRequestOverlappingInfo,
  LeaveRequestQuota,
  getLeavePolicyFromQuota
} from '@vacationtracker/shared/components/add-request-leave-additional-info'
import LeaveForm from '@vacationtracker/shared/components/leave-form'

import { ILeaveFormSaveData } from '@vacationtracker/shared/components/leave-form/types'
import { IGetLeaveRequest, IGetLeaveRequestData, IUserQuota } from '../../../../frontend/src/types/custom-queries'
import { IApprover, ILeaveDayDetails, ILeaveRequestCreatedEvent } from '@vacationtracker/shared/types/leave-request'
import { selectLeaveRequestActionEventSlice, setLeaveRequestActionEvent } from '../../store/leave-request-action-event-slice'
import { IQuota } from '@vacationtracker/shared/types/calculations'
import { IConnectedGoogleCalendar, IConnectedOutlookCalendar } from '@vacationtracker/shared/types/calendar'
import {
  GetTeamsForApproverData,
  IGetLeaveTypesForUserLeaveRequestData,
  IGetUserOverlappingInfo,
  IGetUserOverlappingInfoData,
  IUserIdAndName,
  IUserLeaveRequestExtended,
  IUserLeaveReuqestLeavePolicy
} from '@vacationtracker/shared/components/add-request-leave-additional-info/types'
import { IGetConnectedCalendarResponseData } from './types'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { AccrualType } from '@vacationtracker/shared/types/leave-policy'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { ICompanyShort } from '../CalendarTabPage/types'
import { getUserWorkingHoursPerDay } from '@vacationtracker/shared/functions/work-week'
import { WorkingHours } from '@vacationtracker/shared/types/work-week'

const { Text, Paragraph, Title } = Typography


const RequestLeaveTabComponent: React.FC = () => {
  const msAuth = new MicrosoftTeams()
  const { formatMessage } = useIntl()
  const { userId } = useAppSelector(selectUserIdSlice)
  const { featureFlags } = useAppSelector(selectFeatureFlagsSlice)
  const dispatch = useAppDispatch()
  const { leaveRequestActionEvent } = useAppSelector(selectLeaveRequestActionEventSlice)
  
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [authCompany, setAuthCompany] = useState<ICompanyShort | null>(null)
  const [user, setUser] = useState<IUserLeaveRequestExtended | null>(null)
  const [currentQuota, setCurrentQuota] = useState<IUserQuota | null>(null)
  const [nextYearQuota, setNextYearQuota] = useState<IUserQuota | null>(null)
  const [showSummary, setShowSummary] = useState(false)
  const [startDate, setStartDate] = useState()
  const [selectedLeaveType, setSelectedLeaveType] = useState<IUserLeaveReuqestLeavePolicy | null>(null)
  const [endDate, setEndDate] = useState()
  const [startTime, setStartTime] = useState()
  const [endTime, setEndTime] = useState()
  const [checkForOverlappingUsers, setCheckForOverlappingUsers] = useState(false)
  const [checkIfSubstituteNeeded, setCheckIfSubstituteNeeded] = useState(false)
  const [overlappingUsersInfo, setOverlapingUsersInfo] = useState<IGetUserOverlappingInfo | null>(null)
  const [isLoadingOverlappingUsersInfo, setIsLoadingOverlappingUsersInfo] = useState(false)
  const [createLeaveLoader, setCreateLeaveLoader] = useState(false)
  const [correlationId, setCorrelationId] = useState(null)
  const [connecteGoogledCalendar, setConnectedGoogleCalendar] = useState<IConnectedGoogleCalendar | null>(null)
  const [connectedOutlookCalendar, setConnectedOutlookCalendar] = useState<IConnectedOutlookCalendar | null>(null)
  const [isLoadingConnectedCalenadar, setIsLoadingConnectedCalenadar] = useState(true)
  const [requestedLeave, setRequestedLeave] = useState<IGetLeaveRequest>()
  const [fetchCompanyPlan] = useManualQuery(getCompanyPlan)
  const [fetchLeaveTypes] = useManualQuery(getLeaveTypesForUser)
  const [fetchOverlappingUsersInfo] = useManualQuery(getOverlappingUsersInfo)
  const [fetchConnectedCalendars] = useManualQuery(getConnectedCalendar)
  const [getSuggestedSubstitutes] = useManualQuery(getSuggestedSubstituteApprovers)
  const [fetchTeamsForApprover] = useManualQuery(getTeamsForApprover)
  const [fetchAutomations] = useManualQuery(getAutomations)
  const [fetchLeaveRequest] = useManualQuery(getLeaveRequestData)
  const [shouldShowSubstituteApprovers, setShouldShowSubstituteApprovers] = useState(false)
  const [substituteApprovers, setSubstituteApprovers] = useState<any[]>([])
  const [showOverlappingInfoAndQuotas, setShowOverlappingInfoAndQuotas] = useState(true)
  const [isLimitedVisibility, setIsLimitedVisibility] = useState(false)
  const [teamIds, setTeamIds] = useState<string[]>([])
  
  useEffect(() => {
    track('MICROSOFT_TAB_LEAVE_REQUEST_TAB_OPENED', {
      platform: 'microsoft',
    })
  }, [])

  useEffect(() => {
    getCompanyInfo()
  }, [])

  useEffect(() => {
    if (isLoading) {
      getLeaveTypes(userId)
    }
  }, [isLoading, userId])

  useEffect(() => {
    if (checkForOverlappingUsers) {
      getOverlappingUsers()
    }
  }, [checkForOverlappingUsers])

  useEffect(() => {
    if (leaveRequestActionEvent && correlationId === leaveRequestActionEvent.correlationId) {
      getLeaveRequest(leaveRequestActionEvent.leaveRequestId as string)
      dispatch(setLeaveRequestActionEvent(null))
    }
  }, [leaveRequestActionEvent])

  useEffect(() => {
    if (checkIfSubstituteNeeded) {
      checkForSubstitutes(userId)
    }
  }, [checkIfSubstituteNeeded])

  useEffect(() => {
    if (shouldShowSubstituteApprovers) {
      getUsersList()
    }
  }, [shouldShowSubstituteApprovers, teamIds])

  useEffect(() => {
    checkLimitedVisibility()
  }, [isLimitedVisibility])

  const getLeaveRequest = async (leaveRequestId: string) => {
    try {
      const leaveRequestResponse = await fetchLeaveRequest({variables: { leaveRequestId }}) as IGetLeaveRequestData
      setRequestedLeave(leaveRequestResponse.data.getLeaveRequest)
      setShowSummary(true)
    } catch (err) {
      console.log('error fetching leave', err)
    }
  }
  
  const getOverlappingUsers = async () => {
    setIsLoadingOverlappingUsersInfo(true)
    setShowOverlappingInfoAndQuotas(true)
    const overlappingResponse = await fetchOverlappingUsersInfo({variables: {
      teamId: user?.team.id,
      leaveRequest: {
        startDate,
        endDate,
        userId: user?.id || userId,
        isPartDay: selectedLeaveType?.allowHalfDays || Boolean(selectedLeaveType?.shortestLeaveInterval !== 'FULL-DAY'),
        partDayStartHour: startTime,
        partDayEndHour: endTime,
        leaveTypeId: selectedLeaveType?.id,
      },
    }}) as IGetUserOverlappingInfoData
    setOverlapingUsersInfo(overlappingResponse?.data?.getOverlappingUsersInfo)
    setCheckForOverlappingUsers(false)
    setIsLoadingOverlappingUsersInfo(false)
  }

  const getUsersWithPagination = async (
    limit: number,
    date?: string,
    nextToken?: string,
    accumulatedResults: IUserIdAndName[] = []
  ) => {
    const response = await getSuggestedSubstitutes({ variables: { limit, nextToken, date }}) 
  
    const {users, nextToken: newNextToken} =
      response.data.getActiveUsersWithPagination
  
    const updatedResults = [...accumulatedResults, ...users]
  
    if (newNextToken) {
      return getUsersWithPagination(limit, date, newNextToken, updatedResults)
    } else {
      return updatedResults
    }
  }

  const getUsersList = async () => {
    try {

      const users = await getUsersWithPagination(300, dayjs(startDate).format('YYYY-MM-DD'))
      // sort and filter out the requesting user
      const sortedUsers = users
        .sort((a, b) => (a.name < b.name ? -1 : 1))
        .filter(
          // filter out users that are on leave
          (user) => {
            const hasOverlap = user.upcomingLeaves.some((leave) =>
              startDate && endDate && areIntervalsOverlapping(
                {
                  start: new Date(leave.startDate),
                  end: new Date(leave.endDate),
                },
                {start: new Date(startDate), end: new Date(endDate)},
                {inclusive: true}
              )
            )
            return !hasOverlap
          }
        )
        .filter((user) => user.id !== userId)
      const sortedAndFilteredUsers = isLimitedVisibility ? sortedUsers.filter((user) => teamIds.includes(user?.teamId as string)) : sortedUsers
      
      setSubstituteApprovers(sortedAndFilteredUsers)
    } catch (err) { 
      Sentry.captureException(err)
      setIsLoading(false)
      console.log('error fetching users list', err) 
    } 
  }

  //@employees: list of users that are overlapping with the leave request
  const checkForSubstitutes = async (userId: string) => {
    //first check if user requesting leave is approver
    const teamsForApprover = await fetchTeamsForApprover({ variables: { id: userId, date: new Date() } }) as GetTeamsForApproverData
    const teams = teamsForApprover.data.getUser.approverToTeams
    const userTeamId = teamsForApprover.data.getUser.teamId

    //create a new array of team ids [string] where user is approver and team where he is user (filter duplicates)
    const userTeamIds = Array.from(new Set([...teams.map((team) => team.id), userTeamId]))

    setTeamIds(userTeamIds)
  

    // check if there are teams were user is only approver
    const teamsWithUserAsOnlyApprover = teams.filter(team => team.approvers.length === 1 && team.approvers[0].id === userId)

    if (teamsWithUserAsOnlyApprover.length) {
      setShouldShowSubstituteApprovers(true)
      setCheckIfSubstituteNeeded(false)
      return
    }
    
    const overlappingApprovers: {
      teamId: string
      teamName: string
      approverId: string
      name: string
    }[] = []

    if(startDate && endDate) {
      teams.forEach(team => {
        team.approvers.forEach(approver => {
          const hasOverlap = approver.upcomingLeaves.some(leave => 
            leave.status === 'APPROVED' &&
            areIntervalsOverlapping(
              { start: new Date(leave.startDate), end: new Date(leave.endDate) },
              { start: new Date(startDate), end: new Date(endDate) },
              { inclusive: true }
            )
          )

          if (hasOverlap) {
            overlappingApprovers.push({
              teamId: team.id,
              teamName: team.name,
              approverId: approver.id,
              name: approver.name,
            })
          }
        })
      })
    }

    if (overlappingApprovers.length) {
      setShouldShowSubstituteApprovers(true)
    } else {
      setShouldShowSubstituteApprovers(false)
    }

    setCheckIfSubstituteNeeded(false)
  }

  const checkLimitedVisibility = async () => {
    const automations = await fetchAutomations()
    const limitedVisibilityAddon = automations.data?.getAutomations?.find((addon) => addon.automationType === 'VISIBILITY')
  
    if (limitedVisibilityAddon?.isActive) {
      setIsLimitedVisibility(true)
    }
  }

  const getCompanyInfo = async () => {
    try {
      const response = await fetchCompanyPlan()
      setAuthCompany(response.data.getCompany)
    } catch (err) {
      console.log('error fetching company plan', err)
    }
  }

  const getLeaveTypes = async (id) => {
    try {
      const response = await fetchLeaveTypes({variables: { id }}) as IGetLeaveTypesForUserLeaveRequestData
      const user = response.data.getUser as IUserLeaveRequestExtended
      const responseFeatureFlags = await getFeatureFlags(user.role, 'REQUEST_LEAVE')
      const connectedCalendarsResponse = await fetchConnectedCalendars({ variables: {
        userId,
      }}) as IGetConnectedCalendarResponseData
      if (connectedCalendarsResponse.data?.getConnectedGooogleCalendar?.calendarId) {
        setConnectedGoogleCalendar(connectedCalendarsResponse.data.getConnectedGooogleCalendar)
      }
      if (connectedCalendarsResponse.data?.getConnectedOutlookCalendar?.calendarId) {
        setConnectedOutlookCalendar(connectedCalendarsResponse.data.getConnectedOutlookCalendar)
      }
      const msContext = await msAuth.getContext()
      const userLanguagePreferance = getPrefferedLanguage(user.locale, msContext.app.locale.toLowerCase())
      setUser({
        ...user,
        locale: userLanguagePreferance.locale,
      })
      dispatch(setFeatureFlags(responseFeatureFlags.enabledFeatures))
      dispatch(setLocale(userLanguagePreferance))
      response.data.getUser.leaveDays.map((quota) => {
        if (isWithinInterval(Date.now(), { start: new Date(quota.yearStart), end: new Date(quota.yearEnd) })) {
          setCurrentQuota(quota)
        } else if (isWithinInterval(addYears(new Date(), 1), { start: new Date(quota.yearStart), end: new Date(quota.yearEnd) })) {
          setNextYearQuota(quota)
        }
      })

      setIsLoadingConnectedCalenadar(false)
      setIsLoading(false)
    } catch (err) {
      console.log('error fetching leave types list', err)
    }
  }

  const resetLeaveRequestForm = () => {
    setShowSummary(false)
    setCreateLeaveLoader(false)
    setOverlapingUsersInfo(null)
    setStartDate(undefined)
    setEndDate(undefined)
    setStartTime(undefined)
    setEndTime(undefined)
  }

  const handleOnLeaveTypeSelected = (selectedLeaveType) => {
    setSelectedLeaveType(selectedLeaveType)
    setOverlapingUsersInfo(null)
    setStartDate(undefined)
    setEndDate(undefined)
    setStartTime(undefined)
    setEndTime(undefined)
  }

  const handleOnEndDateSelected = (dateRange) => {
    const {startDate, endDate} = dateRange
    user?.leaveDays.map(quota => {
      if (isWithinInterval(new Date(startDate), { start: new Date(quota.yearStart), end: new Date(quota.yearEnd) })) {
        setCurrentQuota(quota)
      } else if (isWithinInterval(addYears(new Date(startDate), 1), { start: new Date(quota.yearStart), end: new Date(quota.yearEnd) })) {
        setNextYearQuota(quota)
      }
    })
    setStartDate(startDate)
    setEndDate(endDate)
    setCheckForOverlappingUsers(true)
    if (
      (user?.role === 'Approver' || user?.role === 'Admin') &&
      (featureFlags.includes(FeatureFlagEnum.substituteApprovers) ||
        authCompany?.plan === SubscriptionPlanEnum.complete)
    ) {
      setCheckIfSubstituteNeeded(true)
    }
  }

  const handleOnStartDateSelected = (startDate) => {
    user?.leaveDays.map(quota => {
      if (isWithinInterval(new Date(startDate), { start: new Date(quota.yearStart), end: new Date(quota.yearEnd) })) {
        setCurrentQuota(quota)
      } else if (isWithinInterval(addYears(new Date(startDate), 1), { start: new Date(quota.yearStart), end: new Date(quota.yearEnd) })) {
        setNextYearQuota(quota)
      }
    })
    setStartDate(startDate)
    setEndDate(startDate)
    setCheckForOverlappingUsers(true)
    setCheckIfSubstituteNeeded(true)
  }

  const handleOnTimeSelected = ({
    startTime,
    endTime,
  }) => {
    setStartTime(startTime)
    setEndTime(endTime)
    setCheckForOverlappingUsers(true)
  }

  const handleOnCancel = () => {
    setIsLoading(true)
    setOverlapingUsersInfo(null)
    setSelectedLeaveType(null)
  }

  const handleSubmit = async (data: ILeaveFormSaveData) => {
    setCreateLeaveLoader(true)
    setCorrelationId(null)
    try {
      const eventBody: Partial<ILeaveRequestCreatedEvent> = {
        eventType: 'LEAVE_REQUEST_CREATED',
        eventGroup: 'USER_LEAVE_REQUEST',
        source: 'microsoft-tabs',
        ...data,
        userId,
      }
      await validateLeaveRequest(eventBody)
      const response = await sendCoreEvent<Partial<ILeaveRequestCreatedEvent>>(eventBody)
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'app.updatedSuccessfully' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setCorrelationId(response.correlationId)
      dispatch(addCorrelationId(response.correlationId))
      setCreateLeaveLoader(false)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setCorrelationId(null)
      track('MICROSOFT_TAB_DASHBOARD_ERROR', {
        error: err.toString(),
        platform: 'microsoft',
      })
      resetLeaveRequestForm()

      if (err?.response?.data?.code === 'BLACKOUT_PERIOD') {
        const data = err?.response?.data?.data
        notification.error({
          message: formatMessage(
            { id: 'automations.blackoutPeriodFromTo' },
            {
              fromDate: format(new Date(data.startDate), 'MMM dd, yyyy'),
              toDate: format(new Date(data.endDate), 'MMM dd, yyyy'),
              endDate: (...chunks) => data.startDate !== data.endDate ? <>{chunks}</> : '',
            }
          ),
          description: err.response.data.message,
          duration: 0,
        })
      } else if (err?.response?.data?.code === 'PROBATION_PERIOD') {
        const data = err?.response?.data?.data
        notification.error({
          message: formatMessage({ id: 'automations.probationPeriodMessages' }, { date: dayjs(data.date as string).format('MMMM Do YYYY.')}),
          description: err.response.data.message,
          duration: 0,
        })
      } else if (err.response?.data?.error) {
        notification.error({
          message: formatMessage({ id: 'error.leaveSubmitError' }),
          description: formatMessage({ id: err.response.data.error }),
          duration: 0,
        })
      } else {
        Sentry.captureException(err)
        notification.error({
          message: formatMessage({ id: 'error.leaveSubmitError' }),
          description: (
            <Paragraph>
              <Text code copyable>{ JSON.stringify(err, null, 4) }</Text>
            </Paragraph>
          ),
          duration: 0,
        })
      }
    }
  }

  const handleSendFeedback = async (data) => {
    await sendCoreEvent<Partial<ILeaveRequestCreatedEvent>>(data)
  }

  const currentLeavePolicy = getLeavePolicyFromQuota(selectedLeaveType?.id as string, currentQuota as IQuota)
  const nextYearLeavePolicy = getLeavePolicyFromQuota(selectedLeaveType?.id as string, nextYearQuota as IQuota)

  if (!isLoading && user && user?.status !== 'ACTIVE') {
    return (
      <Card bordered={false}>
        <Title level={4} className='main-content-header-title'>
          <IntlMessages id="app.requestLeave" />
        </Title>
        <Alert
          message={formatMessage({ id: 'bot.message.sorryYouAreNotActive' })}
          type='warning'
        />
      </Card>
    )
  }

  return (
    <Card bordered={false}>
      <Title level={4} className='main-content-header-title'>
        <IntlMessages id="app.requestLeave" />
      </Title>

      {isLoading || isLoadingConnectedCalenadar ? <CircularProgress /> : null}

      {!isLoading && showSummary ? <LeaveRequestSummary
        isLeaveAddition={false}
        onSave={handleSendFeedback}
        userId={userId}
        numberOfWorkingHoursPerDay={getUserWorkingHoursPerDay(user?.workHours)}
        leaveRequest={{
          requestedDays: requestedLeave?.workingDays as number,
          daysList: requestedLeave?.daysList as ILeaveDayDetails[],
          currentDays:currentLeavePolicy?.currentDays as number,
          requestedNextDays: overlappingUsersInfo?.year2Days as number,
          nextDays: nextYearLeavePolicy?.currentDays as number,
          leaveTypeName: selectedLeaveType?.leaveType?.name as string,
          hasUnlimitedDays: Boolean(currentLeavePolicy?.hasUnlimitedDays),
          hasUnlimitedDaysNextYear: Boolean(nextYearLeavePolicy?.hasUnlimitedDays),
          isApprovalRequired: selectedLeaveType?.isApprovalRequired,
          leavePeriod: getLeavePeriod({
            isPartDay: selectedLeaveType?.id.includes('|part-day'),
            startDate,
            endDate,
            partDayStartHour: startTime,
            partDayEndHour: endTime,
            formatMessage,
            locale: user?.locale,
            leaveRequestWorkingDays: overlappingUsersInfo?.calendarDays,
            hourFormat: user?.hourFormat,
          }),
        }}
        approvers={user?.team.approvers as IApprover[]}
        resetLeaveRequestForm={resetLeaveRequestForm}
        futureAccruals={{
          allowAdvanceAccrualUsage: selectedLeaveType?.allowAdvanceAccrualUsage,
          endDate: endDate as unknown as string,
          daysOnLeaveEndDates: overlappingUsersInfo?.daysOnLeaveEndDates,
        }}
        locale={user?.locale as LocaleEnum}
      /> : null}

      {!isLoading && !showSummary && <div>
        <LeaveForm
          authUserId={user?.id as string}
          authUserRole={user?.role as string}
          hourFormat={user?.hourFormat}
          loading={createLeaveLoader}
          listOfUsers={[user as IUserLeaveRequestExtended]}
          onSave={(data: ILeaveFormSaveData) => handleSubmit(data)}
          onLeaveTypeSelected={handleOnLeaveTypeSelected}
          onEndDateSelected={handleOnEndDateSelected}
          onStartDateSelected={handleOnStartDateSelected}
          onHandleTimeSelected={handleOnTimeSelected}
          formType={'request'}
          onCancel={handleOnCancel}
          connectedGoogleCalendar={connecteGoogledCalendar}
          shouldShowSubstituteApprovers={shouldShowSubstituteApprovers}
          substituteApprovers={substituteApprovers}
          connectedOutlookCalendar={connectedOutlookCalendar}
        />
      </div>}

      {!showSummary && showOverlappingInfoAndQuotas && <LeaveRequestOverlappingInfo
        isLoadingOverlappingUsersInfo={isLoadingOverlappingUsersInfo}
        overlappingUsersInfo={overlappingUsersInfo}
      />}

      {!showSummary && showOverlappingInfoAndQuotas &&
        <LeaveRequestQuota
          currentLeavePolicy={currentLeavePolicy}
          nextYearLeavePolicy={nextYearLeavePolicy}
          currentQuota={currentQuota}
          nextYearQuota={nextYearQuota}
          user={user}
          overlappingUsersInfo={overlappingUsersInfo}
          workingHours={user?.workHours as WorkingHours}
        />
      }
      {!isLoadingOverlappingUsersInfo && showOverlappingInfoAndQuotas && !showSummary && selectedLeaveType?.allowAdvanceAccrualUsage && shouldShowFutureAccruedDaysInfo({
        accrualType: selectedLeaveType?.accrualType as AccrualType,
        allowAdvanceAccrualUsage: selectedLeaveType?.allowAdvanceAccrualUsage,
        currentYearDays: overlappingUsersInfo?.daysOnLeaveEndDates,
        leaveRequestEndDate: endDate,
        currentDays: currentLeavePolicy?.currentDays,
      }) && <Alert
        message={formatMessage(
          { id: 'email.notification.accruedDaysInfoForUser' },
          { amount: displayLeaveInDaysAndHours({ 
            value: currentLeavePolicy?.currentDays as number,
            formatMessage,
            numberOfWorkingHoursPerDay: getUserWorkingHoursPerDay(user?.workHours),
          }),
          accrued:  displayLeaveInDaysAndHours({ 
            value: overlappingUsersInfo?.daysOnLeaveEndDates as number,
            formatMessage,
            numberOfWorkingHoursPerDay: getUserWorkingHoursPerDay(user?.workHours),
          }),
          earningDate: formatDateToLocale(endDate as unknown as string, 'dddd, MMM D, YYYY', user?.locale),
          })}
        type='info' />}
    </Card>
  )

}

export default RequestLeaveTabComponent
