import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useManualQuery } from 'graphql-hooks'
import { Card, notification, Button, Modal, Table, Collapse, Empty, Typography } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import moment from 'moment'
import { isEqual } from 'lodash'
import { addMonths, addYears, endOfMonth, endOfYear, format, isAfter, startOfMonth, startOfYear, subYears }  from 'date-fns'
import { isEmpty, sortBy, without, includes } from 'lodash'
import * as Sentry from '@sentry/react'
import { sortTodaysLeaves } from '@vacationtracker/shared/components/utils/sorting'
import FilterAdvanced from '@vacationtracker/shared/components/filter-advanced'
import { IFilter } from '@vacationtracker/shared/types/filter'
import ExternalLink from '../../components/external-link-icon'

import { MicrosoftTeams } from '../../services/auth/microsoft/microsoft'
import { track } from '../../services/analytics/analytics'
import { getDashboardData, getLeavesByDateAndStatus, getPendingForApproverById} from '../../graphql/custom-queries'
import { useAppSelector, useAppDispatch } from '../../store/hooks'
import { addCorrelationId } from '../../store/NotificationsSlice'
import { selectUserIdSlice } from '../../store/UserIdSlice'
import { setFeatureFlags, selectFeatureFlagsSlice } from '../../store/FeatureFlagsSlice'
import { setLocale } from '../../store/LocaleSlice'
import { getPrefferedLanguage } from '../../util/get-preffered-language'

import IntlMessages from '../../util/IntlMessages'
import holidayDataWrapper from '../../util/holiday-data-wrapper'
import DenyWithReasonForm from '../../components/deny-with-reason-form'
import LeavesBox from '@vacationtracker/shared/components/leaves-box'
import CircularProgress from '../../components/circular-progress'
import LeaveForm from '@vacationtracker/shared/components/leave-form'
import FormattedDate from '@vacationtracker/shared/components/formatted-date'

import { ICompany, SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { ITeam, ILocation, ILabel, IFilterConfig, ShowMoreTargetEnum } from './types'
import { getFeatureFlags, sendCoreEvent, validateLeaveRequest } from '../../services/api/vacationtracker'
import {
  IDashboardLeaveRequest,
  ILeaveRequestApprovedEvent,
  ILeaveRequestCancelledEvent,
  ILeaveRequestCreatedEvent,
  ILeaveRequestDeniedEvent
} from '@vacationtracker/shared/types/leave-request'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { ILeaveFormSaveData } from '@vacationtracker/shared/components/leave-form/types'
import { selectLeaveRequestActionEventSlice, setLeaveRequestActionEvent } from '../../store/leave-request-action-event-slice'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { UserAvatar } from '@vacationtracker/shared/components/user-avatar'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'

const { Panel } = Collapse
const { Paragraph, Text } = Typography


export interface IGetLeaveRequestsByDateResponse {
  leaveRequests: IDashboardLeaveRequest[]
  nextToken?: string
  total?: number
}
export interface IGetLeavesByDateAndStatus {
  getLeaveRequestByDate: IGetLeaveRequestsByDateResponse
}

const SCHEDULED_LEAVES_LIMIT = 70

interface IUser {
  id: string
  name: string
  imageUrl: string
  role: string
  isAdmin: boolean
  status: string
  startDate: string
  team: {
    id: string
    name: string
  }
  location: {
    id: string
    name: string
    workWeek: string
    timezone: string
    firstDayOfWeek: string
    leavePolicies: []
  }
  locale: LocaleEnum.en
  hourFormat: HourFormatEnum
}


const DashboardTabComponent: React.FC = () => {
  const msAuth = new MicrosoftTeams()
  const { userId } = useAppSelector(selectUserIdSlice)
  const { featureFlags } = useAppSelector(selectFeatureFlagsSlice)
  const dispatch = useAppDispatch()

  const { formatMessage } = useIntl()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [openLeavesRequests, setOpenLeavesRequests] = useState<IDashboardLeaveRequest[]>([])
  const [moreOpenLeavesRequests, setMoreOpenLeavesRequests] = useState<IDashboardLeaveRequest[]>([])
  const [offLeavesToday, setLeavesOffToday] = useState<IDashboardLeaveRequest[]>([])
  const [moreOffLeavesToday, setMoreLeavesOffToday] = useState<IDashboardLeaveRequest[]>([])
  const [upcomingLeaves, setUpcomingLeaves] = useState<IDashboardLeaveRequest[]>([])
  const [moreUpcomingLeaves, setMoreUpcomingLeaves] = useState<IDashboardLeaveRequest[]>([])
  const [filteredOpenLeavesRequests, setFilteredOpenLeavesRequests] = useState<IDashboardLeaveRequest[]>([])
  const [filteredMoreOpenLeavesRequests, setFilteredMoreOpenLeavesRequests] = useState<IDashboardLeaveRequest[]>([])
  const [filteredOffLeavesToday, setFilteredLeavesOffToday] = useState<IDashboardLeaveRequest[]>([])
  const [filteredMoreOffLeavesToday, setFilteredMoreLeavesOffToday] = useState<IDashboardLeaveRequest[]>([])
  const [filteredUpcomingLeaves, setFilteredUpcomingLeaves] = useState<IDashboardLeaveRequest[]>([])
  const [filteredMoreUpcomingLeaves, setFilteredMoreUpcomingLeaves] = useState<IDashboardLeaveRequest[]>([])
  const [teams, setTeams] = useState<ITeam[]>([])
  const [locations, setLocations] = useState<ILocation[]>([])
  const [labels, setLabels] = useState<ILabel[]>([])
  const [visibleLeaveForm, setVisibleLeaveForm] = useState(false)
  const [upcomingHolidays, setUpcomingHolidays]: any = useState([])
  const [moreUpcomingHolidays, setMoreUpcomingHolidays]: any = useState([])
  const [loadingOpenLeavesRequests, setLoadingOpenLeavesRequests] = useState(true)
  const [selectedLeaveRequest, setLeaveRequest]: any = useState({})
  const [visibleDenyModal, setVisibleDenyModal] = useState(false)
  const [approverToUsers, setApproverToUsers]: any = useState([])
  const [company, setCompany] = useState<ICompany | null>(null)
  const [user, setUser] = useState<IUser | null>(null)
  const [createLeaveLoader, setCreateLeaveLoader] = useState(false)
  const [filter, setFilter] = useState<IFilter>({
    locationIds: [],
    teamIds: [],
    labelIds: [],
  })
  const [showMore, setShowMore] = useState<ShowMoreTargetEnum[] | []>([])
  const { leaveRequestActionEvent } = useAppSelector(selectLeaveRequestActionEventSlice)
  const [leaveRequestsNextPaginationToken, setLeaveRequestsNextPaginationToken] = useState<string | undefined>(undefined)
  const [isWebDesktopClient, setIsWebDesktopClient] = useState(false)
  const today = new Date()

  useEffect(() => {
    handleFilter(filter)
  }, [filter, isLoading, loadingOpenLeavesRequests])

  useEffect(() => {
    if (leaveRequestActionEvent) {
      fetchData(userId)
      dispatch(setLeaveRequestActionEvent(null))
    }
  }, [leaveRequestActionEvent])

  useEffect(() => {
    if (userId) {
      fetchData(userId)
    }
  }, [userId])

  const [fetchDashboardData] = useManualQuery(getDashboardData)
  const [fetchLeavesByDateAndStatus] = useManualQuery(getLeavesByDateAndStatus)
  const [fetchPendingForApproverById] = useManualQuery(getPendingForApproverById)

  async function loadUpcomingLeaves(
    startDate: Date,
    endDate: Date,
    limit: number,
    nextToken?: string
  ): Promise<IGetLeaveRequestsByDateResponse> {
    const response = await fetchLeavesByDateAndStatus({variables: {
      dateStart: format(startDate, 'yyyy-MM-dd'),
      dateEnd: format(endDate, 'yyyy-MM-dd'),
      status: 'APPROVED',
      limit,
      nextToken,
    }}) as GraphQLResult<IGetLeavesByDateAndStatus>

    if (response.errors?.length || !response || !response.data || !response.data.getLeaveRequestByDate) {
      throw new Error(JSON.stringify(response))
    }
    return response.data?.getLeaveRequestByDate
  }

  const loadMoreUpcomingLeaves = async (
    isInitial = false,
    now = today,
    year = today.getFullYear(),
    month = today.getMonth(),
    nextToken = 'NONE'
  ) => {
    const shouldFilter = !isEmpty(filter.labelIds) || !isEmpty(filter.locationIds) || !isEmpty(filter.teamIds)
    const todaysDate = now
    const endOf6stMonth = endOfMonth(addMonths(new Date(year, month, 15), 5))
    try {
      const response = await loadUpcomingLeaves(todaysDate, endOf6stMonth, SCHEDULED_LEAVES_LIMIT, nextToken)
      setLeaveRequestsNextPaginationToken(response.nextToken)

      if (response.nextToken && response.leaveRequests.length === 0) {
        return await loadMoreUpcomingLeaves(false, now, year, month, response.nextToken)
      }

      if (isInitial) {
        const upcomingLeavesData = response.leaveRequests
          .filter(leave => {
            if(isAfter(new Date(leave.startDate), today)) {
              return leave
            }
          })

        if (shouldFilter) {
          filterData({
            filter,
            initialData: upcomingLeavesData,
            moreInitialData: [],
            filteredDataSetter: setFilteredUpcomingLeaves,
            filteredMoreDataSetter: setFilteredMoreUpcomingLeaves,
            initialDataSetter: setUpcomingLeaves,
            moreInitialDataSetter: setMoreUpcomingLeaves,
            pickFirstFive: false,
          })
        } else {
          setUpcomingLeaves(upcomingLeavesData)
          setMoreUpcomingLeaves([])
          setFilteredUpcomingLeaves(upcomingLeavesData)
          setFilteredMoreUpcomingLeaves([])
        }
        return
      }
      const moreUpcomingLeavesNew = response.leaveRequests
        .filter(leave => {
          if(isAfter(new Date(leave.startDate), today)) {
            return leave
          }
        })

      if (shouldFilter) {
        filterData({
          filter,
          initialData: [
            ...upcomingLeaves,
            ...moreUpcomingLeavesNew,
          ],
          moreInitialData: [],
          filteredDataSetter: setFilteredUpcomingLeaves,
          filteredMoreDataSetter: setFilteredMoreUpcomingLeaves,
          initialDataSetter: setUpcomingLeaves,
          moreInitialDataSetter: setMoreUpcomingLeaves,
          pickFirstFive: false,
        })
      } else {
        setUpcomingLeaves([
          ...upcomingLeaves,
          ...moreUpcomingLeavesNew,
        ])
        setMoreUpcomingLeaves([])
        setFilteredUpcomingLeaves([
          ...upcomingLeaves,
          ...moreUpcomingLeavesNew,
        ])
        setFilteredMoreUpcomingLeaves([])
      }
    } catch (err) {
      Sentry.captureException(err)

      notification.error({
        key: 'calendar-loading-error',
        message: formatMessage({ id: 'error.leaveRequestsLoadingError.title' }),
        description: (<>
          <Paragraph>{
            formatMessage({ id: 'error.leaveRequestsLoadingError.description' }, {
              link: (...chunks) => <a href="https://vacationtracker.crisp.help/en/" target="_blank" rel="noreferrer">{chunks}</a>,
            })
          }</Paragraph>
        </>),
        duration: 0,
      })
    }
  }

  const fetchData = async (id: string) => {
    try {
      const now = new Date()
      const monthStart = format(startOfMonth(new Date(now.getFullYear(), now.getMonth(), 15)), 'yyyy-MM-dd')
      const endOf2ndMonth = format(endOfMonth(addMonths(new Date(now.getFullYear(), now.getMonth(), 15), 1)), 'yyyy-MM-dd')

      const response = await fetchDashboardData({variables: {
        id,
        date: format(now, 'yyyy-MM-dd'),
        dateStart: monthStart,
        dateEnd: endOf2ndMonth,
        status: 'APPROVED',
      }})
      setApproverToUsers(response.data.getUser?.approverTo)

      const locationsUpcomingHolidays: any = []
      response.data.getLocationList.forEach(location => {
        location.holidays.forEach(holidaysByYear => {
          if (holidaysByYear.year >= moment().format('YYYY')) {
            holidaysByYear.holidays.forEach(holiday => {
              if (holiday.date >= moment().format('YYYY-MM-DD')) {
                locationsUpcomingHolidays.push({
                  date: holiday.date,
                  name: holiday.name,
                  location: location.name,
                  multiDayId: holiday.multiDayId,
                  isHalfDay: holiday.isHalfDay,
                })
              }
            })
          }
        })
      })

      const user = response.data.getUser

      const responseFeatureFlags = await getFeatureFlags(user.role, 'DASHBOARD')
      dispatch(setFeatureFlags(responseFeatureFlags.enabledFeatures))

      const upcomingHolidaysList = holidayDataWrapper(locationsUpcomingHolidays)
      await loadMoreUpcomingLeaves(true)

      setUpcomingHolidays(upcomingHolidaysList.splice(0, 5))
      setMoreUpcomingHolidays(upcomingHolidaysList)
      setCompany(response.data.getCompany)

      const msContext = await msAuth.getContext()
      const userLanguagePreferance = getPrefferedLanguage(user.locale, msContext.app.locale.toLowerCase())
      // https://learn.microsoft.com/en-us/javascript/api/@microsoft/teams-js/hostclienttype?view=msteams-client-js-latest
      setIsWebDesktopClient(msContext?.app?.host?.clientType === 'web' || msContext?.app?.host?.clientType === 'desktop')
      setUser({
        ...user,
        locale: userLanguagePreferance.locale,
      })
      dispatch(setLocale(userLanguagePreferance))
      fetchApprovedLeavesForToday(filter)
      if (response.data.getUser.role !== 'User') {
        fetchOpenLeaves(response.data.getUser.role, id, filter)
      }

      setLocations(response.data.getLocationList.map(l => ({name: l.name, id: l.id})))
      setTeams(response.data.getTeamListV2)
      setLabels(response.data.getLabels)
      setIsLoading(false)
    } catch (err: any) {
      track('MICROSOFT_TAB_DASHBOARD_ERROR', {
        error: err.toString(),
        platform: 'microsoft',
      })
      console.log('error fetching load off today', err)
    }
  }

  const fetchOpenLeaves = async (role, id, availableFilter: IFilter, nextToken = 'NONE') => {
    setLoadingOpenLeavesRequests(true)
    setOpenLeavesRequests([])
    setMoreOpenLeavesRequests([])
    setFilteredOpenLeavesRequests([])
    setFilteredMoreOpenLeavesRequests([])
    try {
      const shouldFilter = !isEmpty(availableFilter.labelIds) || !isEmpty(availableFilter.locationIds) || !isEmpty(availableFilter.teamIds)
      if (role === 'Admin') {
        const now = new Date()
        const response = await fetchLeavesByDateAndStatus({variables: {
          dateStart: format(startOfYear(subYears(now, 1)), 'yyyy-MM-dd'),
          dateEnd: format(endOfYear(addYears(now, 1)), 'yyyy-MM-dd'),
          status: 'OPEN',
          limit: 100,
          nextToken,
        }}) as GraphQLResult<IGetLeavesByDateAndStatus>

        if (response.data?.getLeaveRequestByDate.nextToken && response.data?.getLeaveRequestByDate?.leaveRequests.length === 0) {
          return await fetchOpenLeaves('Admin', userId, filter, response.data.getLeaveRequestByDate.nextToken)
        }

        const openLeavesRequestsData = response?.data?.getLeaveRequestByDate?.leaveRequests.filter(leave => {
          const approvers = leave.user.team.approvers.map(approver => approver.id)
          return approvers.includes(id)
        }) as IDashboardLeaveRequest[]
        const openLeaveRequestStateData = openLeavesRequestsData.splice(0, 5)
        if (shouldFilter) {
          filterData({
            filter,
            initialData: openLeaveRequestStateData,
            moreInitialData: openLeavesRequestsData,
            filteredDataSetter: setFilteredOpenLeavesRequests,
            filteredMoreDataSetter: setFilteredMoreOpenLeavesRequests,
            initialDataSetter: setOpenLeavesRequests,
            moreInitialDataSetter: setMoreOpenLeavesRequests,
            pickFirstFive: true,
          })
        } else {
          setOpenLeavesRequests(openLeaveRequestStateData)
          setMoreOpenLeavesRequests(openLeavesRequestsData)
          setFilteredOpenLeavesRequests(openLeaveRequestStateData)
          setFilteredMoreOpenLeavesRequests(openLeavesRequestsData)
        }
      }
      if (role === 'Approver') {
        const response = await fetchPendingForApproverById({variables: { approverId: id }}) as any
        const openLeavesRequestsForApprover = response.data.getPendingForApprover
        const openLeaveRequestStateData = openLeavesRequestsForApprover.splice(0, 5)
        if (shouldFilter) {
          filterData({
            filter,
            initialData: openLeaveRequestStateData,
            moreInitialData: openLeavesRequestsForApprover,
            filteredDataSetter: setFilteredOpenLeavesRequests,
            filteredMoreDataSetter: setFilteredMoreOpenLeavesRequests,
            initialDataSetter: setOpenLeavesRequests,
            moreInitialDataSetter: setMoreOpenLeavesRequests,
            pickFirstFive: true,
          })
        } else {
          setOpenLeavesRequests(openLeaveRequestStateData)
          setMoreOpenLeavesRequests(openLeavesRequestsForApprover)
          setFilteredOpenLeavesRequests(openLeaveRequestStateData)
          setFilteredMoreOpenLeavesRequests(openLeavesRequestsForApprover)
        }
      }

      setLoadingOpenLeavesRequests(false)
    } catch (err: any) {
      track('MICROSOFT_TAB_DASHBOARD_ERROR', {
        error: err.toString(),
        platform: 'microsoft',
      })
      console.log('error fetching open leaves', err)
    }
  }

  const fetchApprovedLeavesForToday = async (availableFilter: IFilter, nextToken = 'NONE') => {
    setLoadingOpenLeavesRequests(true)
    setLeavesOffToday([])
    setMoreLeavesOffToday([])
    setFilteredLeavesOffToday([])
    setFilteredMoreLeavesOffToday([])
    try {
      const shouldFilter = !isEmpty(availableFilter.labelIds) || !isEmpty(availableFilter.locationIds) || !isEmpty(availableFilter.teamIds)
      const now = new Date()
      const response = await fetchLeavesByDateAndStatus({variables: {
        dateStart: now,
        dateEnd: now,
        status: 'APPROVED',
        limit: 100,
        nextToken,
      }}) as GraphQLResult<IGetLeavesByDateAndStatus>

      if (response.data?.getLeaveRequestByDate.nextToken && response.data?.getLeaveRequestByDate?.leaveRequests.length === 0) {
        return await fetchApprovedLeavesForToday(filter, response.data.getLeaveRequestByDate.nextToken)
      }

      const leavesOffRequestsData = response?.data?.getLeaveRequestByDate?.leaveRequests as IDashboardLeaveRequest[]
      const leavesOffTodayStateData = leavesOffRequestsData.splice(0, 5)
      if (shouldFilter) {
        filterData({
          filter,
          initialData: leavesOffTodayStateData,
          moreInitialData: leavesOffTodayStateData,
          filteredDataSetter: setFilteredLeavesOffToday,
          filteredMoreDataSetter: setFilteredMoreLeavesOffToday,
          initialDataSetter: setLeavesOffToday,
          moreInitialDataSetter: setMoreLeavesOffToday,
          pickFirstFive: true,
        })
      } else {
        setLeavesOffToday(leavesOffTodayStateData)
        setMoreLeavesOffToday(leavesOffRequestsData)
        setFilteredLeavesOffToday(leavesOffTodayStateData)
        setFilteredMoreLeavesOffToday(leavesOffRequestsData)
      }
      setLoadingOpenLeavesRequests(false)
    } catch (err: any) {
      track('MICROSOFT_TAB_DASHBOARD_ERROR', {
        error: err.toString(),
        platform: 'microsoft',
      })
      console.log('error fetching open leaves', err)
    }
  }

  const onUpdateLeaveRequest = async (leave, leaveStatus, statusReason?: string) => {
    try {
      if (leaveStatus) {
        track('MICROSOFT_TAB_DASHBOARD_LEAVE_REQUEST_APPROVED', {
          platform: 'microsoft',
        })
      }
      if (!leaveStatus) {
        track('MICROSOFT_TAB_DASHBOARD_LEAVE_REQUEST_DENIED', {
          platform: 'microsoft',
        })
      }
      const response = await sendCoreEvent<Partial<ILeaveRequestApprovedEvent | ILeaveRequestDeniedEvent>>({
        eventType: leaveStatus ? 'LEAVE_REQUEST_APPROVED' : 'LEAVE_REQUEST_DENIED',
        eventGroup: 'USER_LEAVE_REQUEST',
        userId: leave.user.id,
        leaveRequestId: leave.id,
        statusReason,
      })

      setVisibleDenyModal(false)

      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: leaveStatus ? 'user.approveInProgress' : 'user.denyInProgress' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      dispatch(addCorrelationId(response.correlationId as string))
    } catch (error) {
      track('MICROSOFT_TAB_DASHBOARD_ERROR', {
        error: error.toString(),
        platform: 'microsoft',
      })
    }
  }

  const handleSubmit = async (data: ILeaveFormSaveData) => {
    setCreateLeaveLoader(true)
    try {
      const eventBody: Partial<ILeaveRequestCreatedEvent> = {
        eventType: 'LEAVE_REQUEST_CREATED',
        eventGroup: 'USER_LEAVE_REQUEST',
        ...data,
        userId,
      }
      await validateLeaveRequest(eventBody)
      const response = await sendCoreEvent<Partial<ILeaveRequestCreatedEvent>>(eventBody)
      track('MICROSOFT_TAB_DASHBOARD_LEAVE_REQUESTED', {
        platform: 'microsoft',
      })
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'app.updatedSuccessfully' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      dispatch(addCorrelationId(response.correlationId))
      setCreateLeaveLoader(false)
      setVisibleLeaveForm(false)
    } catch (err: any) {
      track('MICROSOFT_TAB_DASHBOARD_ERROR', {
        error: err.toString(),
        platform: 'microsoft',
      })
      setCreateLeaveLoader(false)
      setVisibleLeaveForm(false)
      if (err.response?.data?.error) {
        notification.error({
          message: formatMessage({ id: 'error.leaveSubmitError' }),
          description: formatMessage({ id: err.response.data.error }),
          duration: 0,
        })
      } else {
        Sentry.captureException(err)
        notification.error({
          message: formatMessage({ id: 'error.leaveSubmitError' }),
          description: (
            <Paragraph>
              <Text code copyable>{ JSON.stringify(err, null, 4) }</Text>
            </Paragraph>
          ),
          duration: 0,
        })
      }
    }
  }

  const filterData = ({
    filter,
    initialData,
    moreInitialData,
    initialDataSetter,
    moreInitialDataSetter,
    filteredDataSetter,
    filteredMoreDataSetter,
    pickFirstFive = true,
  }: IFilterConfig) => {
    const { labelIds, locationIds,teamIds } = filter
    const data = [...initialData, ...moreInitialData]
    const dataFiltered = data
      .filter((leave) => {
        if (isEmpty(locationIds)) {
          return leave
        } else if ( locationIds.find(id => id === leave.user.location.id)) {
          return leave
        }
      })
      .filter((leave) => {
        if (isEmpty(teamIds)) {
          return leave
        } else if (teamIds.find(id => id === leave.user.team.id)) {
          return leave
        }
      })
      .filter((leave) => {
        if (isEmpty(labelIds)) {
          return leave
        } else if (labelIds.find(id => leave.user?.labels && leave.user.labels.find(label => label.id === id))) {
          return leave
        }
      })

    const sortedAndUniqFilteredData = sortBy(dataFiltered, ['startDate'])

    if (!pickFirstFive) {
      filteredDataSetter(sortedAndUniqFilteredData)
      filteredMoreDataSetter([])
    } else {
      let firstFiveElements
      let restOfElements
      if (sortedAndUniqFilteredData.length > 4) {
        firstFiveElements = sortedAndUniqFilteredData.splice(0, 5)
        restOfElements = sortedAndUniqFilteredData
      } else {
        firstFiveElements = sortedAndUniqFilteredData
        restOfElements = []
      }
      filteredDataSetter(firstFiveElements)
      filteredMoreDataSetter(restOfElements)
    }

    if (initialDataSetter) {
      initialDataSetter(initialData)
    }

    if (moreInitialDataSetter) {
      moreInitialDataSetter(moreInitialData)
    }
  }

  const handleFilter = (filterArg: IFilter, filterOpenLeaves = true) => {
    if(!isEqual(filterArg, filter)) {
      setFilter(filterArg)
    }

    const { labelIds, locationIds, teamIds } = filterArg
    if (labelIds.length === 0 && locationIds.length === 0 && teamIds.length === 0 ) {
      setFilteredOpenLeavesRequests(openLeavesRequests)
      setFilteredMoreOpenLeavesRequests(moreOpenLeavesRequests)
      setFilteredLeavesOffToday(offLeavesToday)
      setFilteredMoreLeavesOffToday(moreOffLeavesToday)
      setFilteredUpcomingLeaves(upcomingLeaves)
      setFilteredMoreUpcomingLeaves(moreUpcomingLeaves)
      return
    }

    if (filterOpenLeaves) {
      filterData({
        filter: filterArg,
        initialData: openLeavesRequests,
        moreInitialData: moreOpenLeavesRequests,
        filteredDataSetter: setFilteredOpenLeavesRequests,
        filteredMoreDataSetter: setFilteredMoreOpenLeavesRequests,
        pickFirstFive: true,
      })
    }
    filterData({
      filter: filterArg,
      initialData: offLeavesToday,
      moreInitialData: moreOffLeavesToday,
      filteredDataSetter: setFilteredLeavesOffToday,
      filteredMoreDataSetter: setFilteredMoreLeavesOffToday,
      pickFirstFive: true,
    })
    filterData({
      filter: filterArg,
      initialData: upcomingLeaves,
      moreInitialData: moreUpcomingLeaves,
      filteredDataSetter: setFilteredUpcomingLeaves,
      filteredMoreDataSetter: setFilteredMoreUpcomingLeaves,
      pickFirstFive: false,
    })
  }

  const handleShowMore = (target: ShowMoreTargetEnum) => {
    return includes(showMore, target) ? setShowMore(without(showMore, target)) : setShowMore([...showMore, target])
  }

  const onCancelLeave = async (leave) => {
    try {
      const response = await sendCoreEvent<Partial<ILeaveRequestCancelledEvent>>({
        eventType: 'LEAVE_REQUEST_CANCELLED',
        eventGroup: 'USER_LEAVE_REQUEST',
        leaveRequestId: leave.id,
        userId: leave.user.id,
      })

      const stateData = upcomingLeaves.filter(upcomingLeave => upcomingLeave.id !== leave.id)
      setUpcomingLeaves(stateData)
      setFilteredUpcomingLeaves(stateData)

      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'user.cancelLeaveRequestInProgress' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })

      dispatch(addCorrelationId(response.correlationId))
    } catch (error) {
      console.log('cancel leave error', error)
      notification.error(error)
    }
  }

  const columns = [{
    title: <IntlMessages id="app.name" />,
    dataIndex: 'name',
    key: 'name',
  }, {
    title: <IntlMessages id="app.location" />,
    dataIndex: 'location',
    key: 'location',
  }, {
    title: 'Date(s)',
    dataIndex: 'date',
    key: 'date',
    // eslint-disable-next-line react/display-name
    render: (date, row) => {
      return (<>
        <FormattedDate
          value={date}
          format="YYYY-MM-DD"
          locale={user?.locale}
        />
        {row.multiDayId && <>- <FormattedDate value={row.endDate} format="YYYY-MM-DD" locale={user?.locale}/></>}
        {row.isHalfDay && <><IntlMessages id="app.halfDayInParenthesis" /></>}
      </>)
    },
  }, {
    title: <IntlMessages id="dashboard.days" />,
    dataIndex: 'date',
    key: 'day',
    // eslint-disable-next-line react/display-name
    render: (date, row: any) => {
      return (<>
        <FormattedDate value={date} format="dddd" locale={user?.locale}/> {row.multiDayId && <>- <FormattedDate value={row.endDate} format="dddd" locale={user?.locale}/>
        </>}
      </>)
    },
  }]

  const handleTransitionToRequestLeaveTab = async () => {
    if (isWebDesktopClient) {
      try {
        await msAuth.goTo('requestleave')
      } catch (error) {
        notification.error({
          message: formatMessage({ id: 'error.goToRequestLeaveTab' }),
          description: formatMessage({ id: error?.message }),
          duration: 0,
        })
      }
    } else {
      setVisibleLeaveForm(true)
    }
  }

  return (
    !user ? <CircularProgress /> : (
      <>
        <Card style={{ margin: 30 }}>
          <div className="user-info">
            <div className="sidebar-avatar-row">
              <UserAvatar id={user.id} name={user.name} avatarSize={40} avatar={user.imageUrl} shape="square" />
              <span className="sidebar-avatar-name">
                {user.name}
                <br />
                <strong>{company?.name}</strong>
              </span>
            </div>
            <div className="topbar-actions">
              <Button type="primary" style={{ marginRight: 5 }} onClick={handleTransitionToRequestLeaveTab}>
                <IntlMessages id="app.requestLeave" />
              </Button>
              <Button type="primary" target="_blank"
                style={{ marginRight: 5 }}
                href={`${process.env.REACT_APP_DASHBOARD_URL}/signin?platform=microsoft&redirect=/app/dashboard?lang=${user?.locale}`}
              >
                <IntlMessages id="app.openDashboard" /> <ExternalLink />
              </Button>
              <Button type="primary" target="_blank" style={{ marginRight: 5 }} href="https://vacationtracker.crisp.help/en/">
                <IntlMessages id="app.helpdesk" /> <ExternalLink />
              </Button>
            </div>
          </div>
        </Card>
        <Card style={{ padding: '4px 15px 0' }}>
          <FilterAdvanced
            page='msTeamsDashboard'
            data={{
              Locations: locations,
              Departments: teams,
              Labels: labels,
            }}
            onChangeFilter={handleFilter}
            showLabels={featureFlags.includes(FeatureFlagEnum.labels) || company?.plan === SubscriptionPlanEnum.complete}
          />
        </Card>
        {user.role !== 'User' &&
          <Card
            title={<><IntlMessages id="dashboard.openLeavesRequests" /> {`(${filteredOpenLeavesRequests.length + filteredMoreOpenLeavesRequests.length})`}</>}
            style={{ margin: 30 }}
            extra={
              <a href={`${process.env.REACT_APP_DASHBOARD_URL}/signin?platform=microsoft&redirect=/app/dashboard?lang=${user?.locale}`} target="_blank" rel="noopener noreferrer">
                <IntlMessages id="app.openDashboard" /> <ExternalLink />
              </a>
            }
          >
            {loadingOpenLeavesRequests ?
              <CircularProgress /> :
              <>
                {filteredOpenLeavesRequests.length > 0 ?
                  filteredOpenLeavesRequests.map(leave => {
                    return (<LeavesBox
                      leaveRequestId={leave.id}
                      userId={leave.user.id}
                      userImage={leave.user.imageUrl}
                      userName={leave.user.name}
                      leaveTypeName={leave.leavePolicy.leaveType.name}
                      startDate={leave.startDate}
                      endDate={leave.endDate}
                      leaveTypeColor={leave.leavePolicy.leaveType.color}
                      leaveStatus="OPEN"
                      type="REQUEST"
                      workingDays={leave.workingDays}
                      daysList={leave.daysList}
                      isPartDay={leave.isPartDay}
                      role={user.role}
                      partDayStartHour={leave.partDayStartHour}
                      partDayEndHour={leave.partDayEndHour}
                      key={leave.id}
                      isHiddenLeaveType={leave.leavePolicy.hideLeaveType}
                      reason={(user.role === 'Admin' || (user.role === 'Approver' && approverToUsers.find(user => user.id === leave.user.id))) && leave.reason}
                      handleDenyWithReason={() => { setVisibleDenyModal(!visibleDenyModal); setLeaveRequest(leave) }}
                      onUpdateLeaveRequest={(type) => { onUpdateLeaveRequest(leave, type) }}
                      isEdited={leave.isEdited}
                      hourlyLeaveAccounting={Boolean(company?.hourlyLeaveAccounting)}
                      approverToUsers={approverToUsers}
                      locale={user.locale}
                      hourFormat={user?.hourFormat}
                    />)
                  }) :
                  <Empty
                    description={formatMessage({ id: 'dashboard.noOpenLeavesRequests' })}
                    image={<img src={require('../../assets/images/empty.png')} />}
                    imageStyle={{ width: '140px', height: '140px', margin: '0 auto' }}
                  />
                }
                {filteredMoreOpenLeavesRequests.length > 0 &&
                <Collapse
                  ghost
                  onChange={() => handleShowMore(ShowMoreTargetEnum.openLeaves)}
                >
                  <Panel
                    header={<IntlMessages id={includes(showMore, ShowMoreTargetEnum.openLeaves) ? 'app.showLess' : 'app.showMore'} />}
                    key="1"
                    showArrow={false}
                  >
                    {filteredMoreOpenLeavesRequests.map(leave => {
                      return (<LeavesBox
                        leaveRequestId={leave.id}
                        userId={leave.user.id}
                        userImage={leave.user.imageUrl}
                        userName={leave.user.name}
                        leaveTypeName={leave.leavePolicy.leaveType.name}
                        startDate={leave.startDate}
                        endDate={leave.endDate}
                        leaveTypeColor={leave.leavePolicy.leaveType.color}
                        leaveStatus="OPEN"
                        type="REQUEST"
                        workingDays={leave.workingDays}
                        daysList={leave.daysList}
                        isPartDay={leave.isPartDay}
                        role={user.role}
                        partDayStartHour={leave.partDayStartHour}
                        partDayEndHour={leave.partDayEndHour}
                        key={leave.id}
                        isHiddenLeaveType={leave.leavePolicy.hideLeaveType}
                        reason={leave.reason}
                        handleDenyWithReason={() => { setVisibleDenyModal(!visibleDenyModal); setLeaveRequest(leave) }}
                        onUpdateLeaveRequest={(type) => { onUpdateLeaveRequest(leave, type) }}
                        hourlyLeaveAccounting={Boolean(company?.hourlyLeaveAccounting)}
                        approverToUsers={approverToUsers}
                        locale={user.locale}
                        hourFormat={user?.hourFormat}
                      />)
                    })}
                  </Panel>
                </Collapse>
                }
              </>
            }
          </Card>
        }
        <Card
          title={<><IntlMessages id="dashboard.peopleOffToday" /> {!isLoading && `(${filteredOffLeavesToday.length + filteredMoreOffLeavesToday.length})`}</>}
          style={{ margin: 30 }}
          // eslint-disable-next-line max-len
          extra={<a href={`${process.env.REACT_APP_DASHBOARD_URL}/signin?platform=microsoft&redirect=/app/dashboard&?lang=${user?.locale}`} target="_blank" rel="noopener noreferrer"><IntlMessages id="app.openDashboard" /> <ExternalLink /></a>}
        >
          {filteredOffLeavesToday.length > 0 ?
            sortTodaysLeaves(filteredOffLeavesToday).map(leave => {
              return (<LeavesBox
                leaveRequestId={leave.id}
                userId={leave.user.id}
                userImage={leave.user.imageUrl}
                userName={leave.user.name}
                leaveTypeName={leave.leavePolicy.leaveType.name}
                startDate={leave.startDate}
                endDate={leave.endDate}
                leaveTypeColor={leave.leavePolicy.leaveType.color}
                leaveStatus="APPROVED"
                type="OFFTODAY"
                role={user.role}
                workingDays={leave.workingDays}
                daysList={leave.daysList}
                isPartDay={leave.isPartDay}
                reason={leave.reason}
                partDayStartHour={leave.partDayStartHour}
                partDayEndHour={leave.partDayEndHour}
                approver={leave.approver.name}
                autoApproved={leave.autoApproved}
                key={leave.id}
                isHiddenLeaveType={leave.leavePolicy.hideLeaveType}
                handleDenyWithReason={() => { return }}
                onUpdateLeaveRequest={() => { return }}
                hourlyLeaveAccounting={Boolean(company?.hourlyLeaveAccounting)}
                approverToUsers={approverToUsers}
                locale={user.locale}
                hourFormat={user?.hourFormat}
              />)
            }) :
            <Empty
              description={formatMessage({ id: 'dashboard.noOneIsOffToday' })}
              image={<img src={require('../../assets/images/empty.png')} />}
              imageStyle={{ width: '140px', height: '140px', margin: '0 auto' }}
            />
          }
          {filteredMoreOffLeavesToday.length > 0 &&
            <Collapse
              ghost
              onChange={() => handleShowMore(ShowMoreTargetEnum.todaysLeaves)}
            >
              <Panel
                header={<IntlMessages id={includes(showMore, ShowMoreTargetEnum.todaysLeaves) ? 'app.showLess' : 'app.showMore'} />}
                key="2"
                showArrow={false}
              >
                {sortTodaysLeaves(filteredMoreOffLeavesToday).map(userData => {
                  return (<LeavesBox
                    leaveRequestId={userData.id}
                    userId={userData.user.id}
                    userImage={userData.user.imageUrl}
                    userName={userData.user.name}
                    leaveTypeName={userData.leavePolicy.leaveType.name}
                    startDate={userData.startDate}
                    endDate={userData.endDate}
                    leaveTypeColor={userData.leavePolicy.leaveType.color}
                    leaveStatus="APPROVED"
                    type="OFFTODAY"
                    role={user.role}
                    workingDays={userData.workingDays}
                    daysList={userData.daysList}
                    isPartDay={userData.isPartDay}
                    reason={userData.reason}
                    partDayStartHour={userData.partDayStartHour}
                    partDayEndHour={userData.partDayEndHour}
                    approver={userData.approver.name}
                    autoApproved={userData.autoApproved}
                    key={userData.id}
                    isHiddenLeaveType={userData.leavePolicy.hideLeaveType}
                    handleDenyWithReason={() => { return }}
                    onUpdateLeaveRequest={() => { return }}
                    hourlyLeaveAccounting={Boolean(company?.hourlyLeaveAccounting)}
                    approverToUsers={approverToUsers}
                    locale={user.locale}
                  />)
                })}
              </Panel>
            </Collapse>
          }
        </Card>
        <Card
          title={<>
            <IntlMessages id="app.scheduledLeaves" />
            {!isLoading && ` (${filteredUpcomingLeaves.length}${leaveRequestsNextPaginationToken && filteredUpcomingLeaves.length >= 2 ? '+' : ''})`}
          </>}
          style={{ margin: 30 }}
          extra={
            <a href={`${process.env.REACT_APP_DASHBOARD_URL}/signin?platform=microsoft&redirect=/app/dashboard?lang=${user?.locale}`} target="_blank" rel="noopener noreferrer">
              <IntlMessages id="app.openDashboard" /> <ExternalLink />
            </a>
          }
        >
          {filteredUpcomingLeaves.length > 0 ?
            filteredUpcomingLeaves.map(leave => {
              return (<LeavesBox
                leaveRequestId={leave.id}
                userId={leave.user.id}
                userImage={leave.user.imageUrl}
                userName={leave.user.name}
                leaveTypeName={leave.leavePolicy.leaveType.name}
                startDate={leave.startDate}
                endDate={leave.endDate}
                leaveTypeColor={leave.leavePolicy.leaveType.color}
                leaveStatus="APPROVED"
                type="UPCOMING"
                role={user.role}
                reason={leave.reason}
                partDayStartHour={leave.partDayStartHour}
                partDayEndHour={leave.partDayEndHour}
                approver={leave?.approver?.name}
                autoApproved={leave.autoApproved}
                workingDays={leave.workingDays}
                daysList={leave.daysList}
                isPartDay={leave.isPartDay}
                key={leave.id}
                isHiddenLeaveType={leave.leavePolicy.hideLeaveType}
                handleDenyWithReason={() => { return }}
                onUpdateLeaveRequest={() => { return }}
                onCancelLeave={() => onCancelLeave(leave)}
                hourlyLeaveAccounting={Boolean(company?.hourlyLeaveAccounting)}
                approverToUsers={approverToUsers}
                locale={user.locale}
              />)
            }) :
            <Empty
              description={formatMessage({ id: 'dashboard.noOneIsTackingLeaves' })}
              image={<img src={require('../../assets/images/empty.png')} />}
              imageStyle={{ width: '140px', height: '140px', margin: '0 auto' }}
            />
          }
          {leaveRequestsNextPaginationToken && <Button
            onClick={() => loadMoreUpcomingLeaves(
              false,
              new Date(),
              new Date().getFullYear(),
              new Date().getMonth(),
              leaveRequestsNextPaginationToken
            )}>
            <IntlMessages id='app.showMore' />
          </Button>
          }
        </Card>
        <Card
          title={<IntlMessages id="dashboard.upcomingHolidays" />}
          style={{ margin: 30 }}
          bodyStyle={{ padding: 0 }}
          extra={
            <a href={`${process.env.REACT_APP_DASHBOARD_URL}/signin?platform=microsoft&redirect=/app/dashboard?lang=${user?.locale}`} target="_blank" rel="noopener noreferrer">
              <IntlMessages id="app.openDashboard" /> <ExternalLink />
            </a>
          }
        >
          <Table
            className='table-horizontal-scroll'
            dataSource={upcomingHolidays}
            columns={columns}
            loading={isLoading}
            rowKey={record => record}
            pagination={false}
          />
          {moreUpcomingHolidays.length > 0 &&
            <Collapse
              ghost
              onChange={() => handleShowMore(ShowMoreTargetEnum.holidays)}
            >
              <Panel
                header={<IntlMessages id={includes(showMore, ShowMoreTargetEnum.holidays) ? 'app.showLess' : 'app.showMore'} />}
                key="4"
                showArrow={false}
              >
                <Table
                  className='table-horizontal-scroll'
                  showHeader={false}
                  dataSource={moreUpcomingHolidays}
                  columns={columns}
                  loading={isLoading}
                  rowKey={record => record}
                  pagination={false}
                />
              </Panel>
            </Collapse>
          }
        </Card>
        <Modal
          title={<IntlMessages id='app.requestLeave' />}
          visible={visibleLeaveForm}
          footer={false}
          onCancel={() => { setVisibleLeaveForm(false) }}
        >
          <LeaveForm
            authUserId={user.id}
            authUserRole={user.role}
            loading={createLeaveLoader}
            listOfUsers={[user]}
            onCancel={() => { setVisibleLeaveForm(false) }}
            onSave={(data: ILeaveFormSaveData) => {
              (async () => {
                await handleSubmit(data)
              })()
            }}
            formType={'request'}
            modalForm={true}
            hourFormat={user?.hourFormat}
          />
        </Modal>
        {visibleDenyModal &&
          <DenyWithReasonForm
            visibleModal={visibleDenyModal}
            leaveRequest={selectedLeaveRequest}
            handleCancel={() => (setVisibleDenyModal(false))}
            onSave={(data) => (onUpdateLeaveRequest(data.leaveRequest, false, data.statusReason as string | undefined))}
          />
        }
      </>
    )
  )
}

export default DashboardTabComponent
